import { render, staticRenderFns } from "./index.vue?vue&type=template&id=835f93ac&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "~/assets/css/home.scss?vue&type=style&index=0&id=835f93ac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "835f93ac",
  null
  
)

export default component.exports