<template>
  <div>
    <b-modal
      size="xl"
      hide-footer
      id="modal-terms-conditions"
      :title="$t('Términos y condiciones')"
    >
      <TermsConditionsContent />
    </b-modal>

    <section class="first-section">
      <b-container fluid>
        <b-row>
          <b-col class="left" cols="12" md="6">
            <!-- <p>{{ $t('EN FROMBEA ENCONTRARÁS') }}</p> -->
            <h1>
              {{ $t('Tu ropa de casa') }}<br />
              {{ $t('siempre a tu gusto') }}
            </h1>
            <p>
              {{ $t('Crea tu producto a través de nuestro simulador.') }}<br />
              {{
                $t(
                  'También puedes visitar nuestra colección de productos ya diseñados.'
                )
              }}
            </p>
            <b-row class="d-flex justify-center flex-gap mb-4 mb-md-0">
              <b-col cols="auto">
                <b-button
                  variant="primary"
                  class="btn-cta"
                  @click="scrollToDesignNow()"
                  >{{ $t('Crea tu producto') }}
                </b-button>
              </b-col>
              <b-col cols="auto">
                <b-button
                  variant="primary"
                  class="btn-cta"
                  :to="
                    localePath({
                      name: 'catalog-slug-pslug',
                      params: {
                        slug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory.parent,
                          'slug'
                        ),
                        pslug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory.child,
                          'slug'
                        ),
                      },
                    })
                  "
                  >{{ $t('Nuestra colección') }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            class="right"
            v-bind:style="{ backgroundImage: 'url(' + ctaImageURI + ')' }"
            cols="12"
            md="6"
          ></b-col>
        </b-row>
      </b-container>
    </section>

    <!-- <section class="feedback-section">
      <b-container>
        <b-container class="wrapper pb-4 pt-4">
          <b-row class="justify-content-center text-center">
            <div class="mr-4">{{ $t('Customer feedback') }}</div>
            <div class="mr-4">
              <b-icon icon="star-fill" /><b-icon icon="star-fill" /><b-icon
                icon="star-fill"
              /><b-icon icon="star-fill" /><b-icon icon="star-fill" />
            </div>
            <div>{{ $t('Basado en +1000 pedidos en 5 años') }}</div>
          </b-row></b-container
        >
      </b-container>
    </section> -->

    <section class="third-section">
      <b-container>
        <b-row>
          <b-col cols="12" md="4" class="col-wrapper">
            <img src="@/assets/img/artesanal.png" />
            <h2>{{ $t('100% Artesanal') }}</h2>
            <p>
              {{
                $t(
                  'Un producto sofisticado y único hecho a mano para ti, en España y sin procesos industriales.'
                )
              }}
            </p>
          </b-col>
          <b-col cols="12" md="4" class="col-wrapper">
            <img src="@/assets/img/100artesanal-copia.png" />
            <h2>{{ $t('Alta Durabilidad') }}</h2>
            <p>
              {{
                $t(
                  'Gracias a su larga vida utiliza nuestros productos tanto como los manteles y sábanas de la abuela.'
                )
              }}
            </p>
          </b-col>
          <b-col cols="12" md="4" class="col-wrapper">
            <img src="@/assets/img/record.png" />
            <h2>{{ $t('Fabricación récord') }}</h2>
            <p>
              {{
                $t(
                  'Obtén tu producto en un plazo máximo de 20 días sin sacrificar ni un ápice de calidad.'
                )
              }}
            </p>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="12" class="text-center mt-5">
            <b-button
              class="mt-4 btn-cta"
              @click="$parent.clickedCTABtnProducts(4)"
              >{{ $t('Diseña ahora') }} <b-icon icon="arrow-right"></b-icon
            ></b-button>
          </b-col>
        </b-row> -->
      </b-container>
    </section>

    <section class="banner1-section">
      <b-container fluid>
        <b-container fluid class="pb-4 pt-4">
          <b-row class="text-center mb-4">
            <b-col
              ><h2>
                {{ $t('Crea tu producto en nuestro simulador') }}
              </h2></b-col
            >
          </b-row>
          <b-row class="justify-content-md-center text-center">
            <b-col
              class="banner1-ps-wrapper mb-5 mb-lg-0"
              cols="12"
              sm="6"
              md="6"
              lg="3"
            >
              <b-link
                :to="
                  localePath({
                    name: 'simulator-slug-pslug',
                    params: {
                      slug: getTranslatedKey(ropaDeMesaCategory, 'slug'),
                      pslug: getTranslatedKey(
                        ropaDeMesaIndividualesCategory,
                        'slug'
                      ),
                    },
                  })
                "
              >
                <img :src="ctaImageSimulator1URI" />
                <div>
                  <b-button variant="primary" block class="btn-cta"
                    >{{ $t('Crea tu individual') }}
                  </b-button>
                </div>
              </b-link>
            </b-col>
            <b-col
              class="banner1-ps-wrapper mb-5 mb-lg-0"
              cols="12"
              sm="6"
              md="6"
              lg="3"
            >
              <b-link
                :to="
                  localePath({
                    name: 'simulator-slug-pslug',
                    params: {
                      slug: getTranslatedKey(ropaDeMesaCategory, 'slug'),
                      pslug: getTranslatedKey(
                        ropaDeMesaServilletasCategory,
                        'slug'
                      ),
                    },
                  })
                "
              >
                <img :src="ctaImageSimulator2URI" />
                <div>
                  <b-button variant="primary" block class="btn-cta"
                    >{{ $t('Crea tu servilleta') }}
                  </b-button>
                </div>
              </b-link> </b-col
            ><b-col
              class="banner1-ps-wrapper mb-5 mb-lg-0"
              cols="12"
              sm="6"
              md="6"
              lg="3"
            >
              <b-link
                :to="
                  localePath({
                    name: 'simulator-slug-pslug',
                    params: {
                      slug: getTranslatedKey(ropaDeMesaCategory, 'slug'),
                      pslug: getTranslatedKey(
                        ropaDeMesaMantelesCategory,
                        'slug'
                      ),
                    },
                  })
                "
              >
                <img :src="ctaImageSimulator3URI" />
                <div>
                  <b-button variant="primary" block class="btn-cta"
                    >{{ $t('Crea tu mantel') }}
                  </b-button>
                </div>
              </b-link> </b-col
            ><b-col
              class="banner1-ps-wrapper mb-5 mb-lg-0"
              cols="12"
              sm="6"
              md="6"
              lg="3"
            >
              <b-link
                :to="
                  localePath({
                    name: 'simulator-slug-pslug',
                    params: {
                      slug: getTranslatedKey(ropaDeMesaCategory, 'slug'),
                      pslug: getTranslatedKey(
                        ropaDeMesaCaminosMesaCategory,
                        'slug'
                      ),
                    },
                  })
                "
              >
                <img :src="ctaImageSimulator4URI" />
                <div>
                  <b-button variant="primary" block class="btn-cta"
                    >{{ $t('Crea tu camino de mesa') }}
                  </b-button>
                </div>
              </b-link>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </section>

    <section class="only-lino-section">
      <b-container>
        <b-row>
          <b-col cols="12" offset-md="4" md="4" class="col-wrapper">
            <img class="img" src="@/assets/img/linoalgodon.png" />
            <p>
              {{ $t('Solo escogemos telas e hilos de fibras 100% naturales.') }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-row class="d-flex justify-content-center">
              <b-col cols="auto">
                <!-- <b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  @click="scrollToDesignNow()"
                  >{{ $t('Crea tu producto') }}
                </b-button> -->
                <b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  :to="localePath({ name: 'fabrics' })"
                  >{{ $t('Visita nuestro catálogo de telas') }}
                </b-button>
              </b-col>
              <!-- <b-col cols="auto">
                <b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  :to="
                    localePath({
                      name: 'catalog-slug',
                      params: {
                        slug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory,
                          'slug'
                        ),
                      },
                    })
                  "
                  >{{ $t('Nuestra colección') }}
                </b-button></b-col
              > -->
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- <section class="concerta-cita-section">
      <b-container>
        <b-container class="pb-4 pt-4">
          <b-row class="text-center text-large">
            <p>
              {{
                $t(
                  '¿Quieres concertar una cita con nosotras? Estaremos encantadas de poder ayudarte. Por favor, rellena este formulario y te llamamos cuando nos digas para asesorarte gratuitamente.'
                )
              }}
            </p>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-center">
              <b-button class="mt-4 btn-cta" @click="goTo('home-clothing-form')"
                >{{ $t('Agenda tu Homeware gratis') }}
                <b-icon icon="arrow-right"></b-icon
              ></b-button>
            </b-col> </b-row
        ></b-container>
      </b-container>
    </section> -->

    <section class="criteria-providers-section">
      <b-container>
        <b-row>
          <p class="heading">
            {{
              $t(
                'Evaluamos a 30 proveedores europeos y solo escogemos los que cumplen con nuestros criterios claves:'
              )
            }}
          </p>
        </b-row>

        <b-row
          class="justify-content-center justify-content-md-between text-center"
        >
          <b-col cols="6" md="2" class="col-wrapper">
            <img class="img" src="@/assets/img/soste.png" />
            <p>{{ $t('Sostenibilidad') }}</p>
          </b-col>
          <b-col cols="6" md="2" class="col-wrapper">
            <img class="img" src="@/assets/img/diamond.png" />
            <p>{{ $t('Calidad') }}</p>
          </b-col>
          <b-col cols="6" md="2" class="col-wrapper">
            <img class="img" src="@/assets/img/users-1.png" />
            <p>{{ $t('Responsabilidad empresarial') }}</p>
          </b-col>
          <b-col cols="6" md="2" class="col-wrapper">
            <img class="img" src="@/assets/img/earth.png" />
            <p>{{ $t('Respeto al medioambiente') }}</p>
          </b-col>
          <b-col cols="6" md="2" class="col-wrapper">
            <img class="img" src="@/assets/img/users-1.png" />
            <p>{{ $t('Comercio justo') }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-row class="d-flex justify-content-center">
              <b-col cols="auto"
                ><b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  @click="scrollToDesignNow()"
                  >{{ $t('Crea tu producto') }}
                </b-button></b-col
              >
              <b-col cols="auto">
                <b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  :to="
                    localePath({
                      name: 'catalog-slug-pslug',
                      params: {
                        slug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory.parent,
                          'slug'
                        ),
                        pslug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory.child,
                          'slug'
                        ),
                      },
                    })
                  "
                  >{{ $t('Nuestra colección') }}
                </b-button></b-col
              >
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="quality-work-section">
      <b-container fluid>
        <b-row class="justify-content-md-between">
          <b-col cols="12" md="5" class="col-wrapper">
            <img class="img" src="@/assets/img/TALLER.jpg" />
          </b-col>
          <b-col cols="12" md="7" class="col-wrapper wrapper-text">
            <b-row class="col-wrapper-row" align-v="center">
              <b-col cols="12">
                <div class="mr-3">
                  <b-icon font-scale="1.5" icon="star-fill" /><b-icon
                    icon="star-fill"
                    font-scale="1.5"
                  /><b-icon icon="star-fill" font-scale="1.5" /><b-icon
                    icon="star-fill"
                    font-scale="1.5"
                  /><b-icon icon="star-fill" font-scale="1.5" />
                </div>
                <p class="text">
                  {{
                    $t(
                      'Todos nuestros trabajos son únicos y 100% a medida. Nuestra manera de hacer el diseño más puro: no añadimos etiquetas porque el protagonista eres tú.'
                    )
                  }}
                </p>
                <p class="text2">
                  {{
                    $t(
                      '– Lo que nos garantiza en nuestros 5 años de vida tener cero devoluciones de nuestros clientes. –'
                    )
                  }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="history-frombea">
      <b-container>
        <b-row class="justify-content-md-between" align-v="center">
          <b-col cols="12" class="col-wrapper">
            <p>{{ $t('CONOCE MÁS SOBRE NUESTRA HISTORIA') }}</p>
            <h2>{{ $t('La historia de from:BEA') }}</h2>
            <p>
              {{
                $t(
                  'Era tradición en casa de su abuela y luego en la de su madre el poner mesas enormes cuidando hasta el último detalle, pues estas casas siempre estaban abiertas y había invitados a los que cuidar.'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'Esta tradición y su paso por el diseño de interiores hacen hoy la marca de from:BEA.'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'En su profesión como interiorista siempre buscaba el personalizar la ropa de casa y sobre todo de la mejor calidad.'
                )
              }}<br /><br />{{
                $t('Y fue cuando tomó la decisión de montar su propio taller.')
              }}
            </p>
            <p>
              {{
                $t(
                  'Esta idea comenzó en casa de Bea, que después de dedicar mucho tiempo a la investigación de los tejidos naturales y en particular del lino, que es un tejido vivo, pudo crear su propio sistema de producción.'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'Apostó por este tejido, el lino, porque a parte de vestir mucho y dar más calidez al entorno, su producción es la menos contaminante y es totalmente biodegradable.'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'Hoy fabricamos Ropa de Mesa, Ropa de Cama, Ropa de Baño y Ropa de Casa.'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'Por nuestros principios y forma de hacer las cosas, from:BEA ha crecido de manera exponencial.'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'Y nos enorgullece saber que nuestro porcentaje de CERO devoluciones es la prueba de ello.'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'Te damos la bienvenida a from:BEA – Tu ropa de casa siempre a tu gusto.'
                )
              }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-row class="d-flex justify-content-center">
              <b-col cols="auto"
                ><b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  @click="scrollToDesignNow()"
                  >{{ $t('Crea tu producto') }}
                </b-button></b-col
              >
              <b-col cols="auto">
                <b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  :to="
                    localePath({
                      name: 'catalog-slug-pslug',
                      params: {
                        slug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory.parent,
                          'slug'
                        ),
                        pslug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory.child,
                          'slug'
                        ),
                      },
                    })
                  "
                  >{{ $t('Nuestra colección') }}
                </b-button></b-col
              >
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- <section ref="home-clothing-form" class="concerta-cita-section">
      <b-container>
        <b-container class="pb-4 pt-4">
          <b-row>
            <b-col class="text-center" cols="12">
              <h2>
                {{ $t('Concierta tu Homeware') }}
                <u>{{ $t('gratuito sin compromiso') }}</u>
              </h2>
            </b-col>
          </b-row>
          <b-form-row>
            <b-col>
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-group
                  id="input-group-2"
                  :label="$t('Nombre:')"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.name"
                    :placeholder="$t('Nombre')"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  :label="$t('Email:')"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    :placeholder="$t('Email')"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  :label="$t('Teléfono:')"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.phone_number"
                    :placeholder="$t('Teléfono')"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  :label="$t('¿Qué tienes pensado personalizar?')"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    v-model="form.customize"
                    :options="customizeOptions"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  :label="$t('¿Qué día prefieres agendar llamada con Bea?')"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    v-model="form.day"
                    :options="dayOptions"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  :label="$t('¿A qué hora prefieres agendar llamada con Bea?')"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    v-model="form.hour"
                    :options="hourOptions"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-row>
                  <b-form-group
                    id="input-opted_in_tc"
                    class="col-12"
                    label-for="opted_in_tc"
                  >
                    <b-form-checkbox
                      name="opted_in_tc"
                      required
                      v-model="form.opted_in_tc"
                    >
                      <span>{{
                        $t(
                          'Consiento el tratamiento de mis datos. FROMBEA. tratará sus datos con la finalidad de contestar a sus consultas, dudas o reclamaciones. Puede ejercer sus derechos de acceso, rectificación, supresión, portabilidad, limitación y oposición, como le informamos en nuestra'
                        )
                      }}</span>
                      <b-link
                        href="#"
                        v-b-modal.modal-terms-conditions
                        class="pr-2 pl-2 primary-color"
                        >{{
                          $t('Política de Privacidad y Aviso Legal')
                        }}</b-link
                      ><span>.</span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-form-row>

                <b-button
                  v-if="!callArrangedSuccessfully"
                  class="mt-4 btn-cta"
                  type="submit"
                  variant="primary"
                  >{{ $t('Concierta tu Homeware') }}
                  <b-icon icon="arrow-right"></b-icon
                ></b-button>

                <b-alert
                  class="mt-4"
                  variant="success"
                  :show="callArrangedSuccessfully"
                  >{{
                    $t(
                      'Cita concertada satisfactoriamente. Nos pondremos en contacto contigo a la hora indicada.'
                    )
                  }}</b-alert
                >
              </b-form></b-col
            >
          </b-form-row></b-container
        >
      </b-container>
    </section> -->

    <section class="manual-book-linen-care-section">
      <b-container>
        <b-row>
          <b-col cols="12" offset-md="3" md="6" class="col-wrapper">
            <img class="img" src="@/assets/img/linoalgodoncare.png" />
            <p>
              {{ $t('El libro del cuidado del lino') }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-row class="d-flex justify-content-center">
              <b-col cols="auto">
                <!-- <b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  @click="scrollToDesignNow()"
                  >{{ $t('Crea tu producto') }}
                </b-button> -->
                <b-button
                  variant="primary"
                  class="mt-2 btn-cta"
                  :href="
                    $i18n.locale === 'en'
                      ? '/files/Lino_y_su_cuidado_en.pdf'
                      : '/files/Lino_y_su_cuidado.pdf'
                  "
                  target="_blank"
                  >{{ $t('Descargar') }}
                </b-button>
              </b-col>
              <!-- <b-col cols="auto">
                <b-button
                  variant="primary"
                  class="mt-4 btn-cta"
                  :to="
                    localePath({
                      name: 'catalog-slug',
                      params: {
                        slug: getTranslatedKey(
                          catalogIndividualesYServilletasCategory,
                          'slug'
                        ),
                      },
                    })
                  "
                  >{{ $t('Nuestra colección') }}
                </b-button></b-col
              > -->
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="say-hello-section">
      <b-container fluid>
        <b-row class="text-center mb-4">
          <b-col>
            <p class="text-hello">
              {{ $t('Dinos hola y síguenos para estar al día con') }}
              <a target="_blank" href="https://www.instagram.com/from_bea/"
                >@frombea</a
              >
            </p></b-col
          >
        </b-row>
        <b-row class="justify-content-md-center text-center">
          <b-col class="banner1-ps-wrapper" cols="6" md="2">
            <img
              :src="
                getImageSocial(
                  1,
                  require('@/assets/img/PastedGraphic-5-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  5,
                  require('@/assets/img/PastedGraphic-11-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  9,
                  require('@/assets/img/PastedGraphic-7-280x280.jpg')
                )
              "
            />
          </b-col>
          <b-col class="banner1-ps-wrapper" cols="6" md="2">
            <img
              :src="
                getImageSocial(
                  2,
                  require('@/assets/img/PastedGraphic-14-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  6,
                  require('@/assets/img/PastedGraphic-10-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  10,
                  require('@/assets/img/PastedGraphic-6-280x280.jpg')
                )
              "
            /> </b-col
          ><b-col class="banner1-ps-wrapper" cols="6" md="2">
            <img
              :src="
                getImageSocial(
                  3,
                  require('@/assets/img/PastedGraphic-13-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  7,
                  require('@/assets/img/PastedGraphic-9-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  11,
                  require('@/assets/img/PastedGraphic-15-280x280.jpg')
                )
              "
            />
          </b-col>
          <b-col class="banner1-ps-wrapper" cols="6" md="2">
            <img
              :src="
                getImageSocial(
                  4,
                  require('@/assets/img/PastedGraphic-12-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  8,
                  require('@/assets/img/PastedGraphic-8-280x280.jpg')
                )
              "
            />
            <img
              :src="
                getImageSocial(
                  12,
                  require('@/assets/img/PastedGraphic-16-1-280x280.jpg')
                )
              "
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import TermsConditionsContent from '@/components/TermsConditionsContent.vue';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'index',
  props: {
    msg: String,
  },
  components: {
    TermsConditionsContent,
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      title: this.$t('seo_home_title'),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('seo_home_description'),
        },
        ...i18nHead.meta,
      ],
    };
  },
  data() {
    return {
      callArrangedSuccessfully: false,
      form: {
        email: '',
        name: '',
        phone_number: '',
        day: null,
        customize: null,
        hour: null,
        opted_in_tc: false,
      },
      customizeOptions: [
        { text: this.$t('-- Selecciona --'), value: null },
        { text: this.$t('Individuales'), value: 'Individuales' },
        { text: this.$t('Servilletas'), value: 'Servilletas' },
        { text: this.$t('Manteles'), value: 'Manteles' },
      ],
      dayOptions: [
        { text: this.$t('-- Selecciona --'), value: null },
        { text: this.$t('Lunes'), value: 'Lunes' },
        { text: this.$t('Miércoles'), value: 'Miércoles' },
        { text: this.$t('Viernes'), value: 'Viernes' },
      ],
      hourOptions: [
        { text: this.$t('-- Selecciona --'), value: null },
        { text: this.$t('10:00 h - 12:00 h'), value: '10:00 h - 12:00 h' },
        { text: this.$t('17:00 h - 19:00 h'), value: '17:00 h - 19:00 h' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getCategories: 'categories/getCategories',
      getWebHomeSettings: 'webHomeSettings/getWebHomeSettings',
      getPredefinedCategories: 'predefinedCategories/getPredefinedCategories',
    }),

    ctaImageURI() {
      if (this.getWebHomeSettings.image_cta) {
        return this.getWebHomeSettings.image_cta;
      }
      // default image
      return require('@/assets/img/photo_home_main_cta.jpg');
    },

    ctaImageSimulator1URI() {
      if (this.getWebHomeSettings.image_simulator_1) {
        return this.getWebHomeSettings.image_simulator_1;
      }
      // default image
      return require('@/assets/img/Individuales.jpeg');
    },

    ctaImageSimulator2URI() {
      if (this.getWebHomeSettings.image_simulator_2) {
        return this.getWebHomeSettings.image_simulator_2;
      }
      // default image
      return require('@/assets/img/Servilletas.jpeg');
    },

    ctaImageSimulator3URI() {
      if (this.getWebHomeSettings.image_simulator_3) {
        return this.getWebHomeSettings.image_simulator_3;
      }
      // default image
      return require('@/assets/img/Manteles.jpeg');
    },

    ctaImageSimulator4URI() {
      if (this.getWebHomeSettings.image_simulator_4) {
        return this.getWebHomeSettings.image_simulator_4;
      }
      // default image
      return require('@/assets/img/caminos-de-mesa.jpeg');
    },

    ropaDeMesaCategory() {
      return this.getCategories.find(
        (x) => x.id === 4
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    ropaDeMesaIndividualesCategory() {
      return this.getCategories.find(
        (x) => x.id === 8
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    ropaDeMesaServilletasCategory() {
      return this.getCategories.find(
        (x) => x.id === 10
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    ropaDeMesaMantelesCategory() {
      return this.getCategories.find(
        (x) => x.id === 9
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    ropaDeMesaCaminosMesaCategory() {
      return this.getCategories.find(
        (x) => x.id === 14
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    catalogIndividualesYServilletasCategory() {
      const r = { child: '', parent: '' };
      r.child = this.getPredefinedCategories.find((x) => x.id === 2);
      r.parent = this.getPredefinedCategories.find(
        (x) => x.id === r.child.parent
      );
      return r;
    },
  },
  methods: {
    getImageSocial(i, default_uri) {
      if (
        this.getWebHomeSettings &&
        `image_social_${i}` in this.getWebHomeSettings &&
        this.getWebHomeSettings[`image_social_${i}`]
      ) {
        return this.getWebHomeSettings[`image_social_${i}`];
      }
      // default image
      return default_uri;
    },
    ...mapMutations({
      setOverlay: 'overlay/setOverlay',
    }),
    scrollToDesignNow() {
      var elem = document.querySelector('.banner1-section');
      elem.scrollIntoView({ behavior: 'smooth' });
    },

    // onSubmit() {
    //   this.callArrangedSuccessfully = false;

    //   const email = this.form.email;
    //   const name = this.form.name;
    //   const phone_number = this.form.phone_number;
    //   const customize = this.form.customize;
    //   const day = this.form.day;
    //   const hour = this.form.hour;
    //   const opted_in_tc = this.form.opted_in_tc;

    //   if (!opted_in_tc) {
    //     return;
    //   }

    //   this.$actions
    //     .postArrangeCall({
    //       email: this.form.email,
    //       name: this.form.name,
    //       phone_number: this.form.phone_number,
    //       customize: this.form.customize,
    //       day: this.form.day,
    //       hour: this.form.hour,
    //     })
    //     .then((yy) => {
    //       this.callArrangedSuccessfully = true;
    //     });
    // },
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" src="~/assets/css/home.scss" scoped></style>
